/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { geolocated } from "react-geolocated";
import { AsyncPaginate } from "react-select-async-paginate";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as appActions from "../../../core/app.store";

import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import {
  changeAlias,
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";
import $ from 'jquery';

import "./search.scss";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as mapHelper from "../../../common/map";
import * as mapDataStore from "../../../redux/store/map-data/map-data.store";

function HomeSearchView(props) {
  const { showLoading } = props;

  const [projectList, setProjectList] = useState();
  const [address, setAddress] = useState("");
  const [searchPlanning, setSearchPlanning] = useState(true);
  const [hasPolygon, setHasPolygon] = useState(0);
  const [selectLatLng, setSelectLatLng] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    accuracy: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetAllPlanningByTypeId(),
      onGetCurrentPosition(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onSetSearchBox = (status) => {
    setSearchPlanning(status);
  }

  const onGetAllPlanningByTypeId = (id) => {
    return new Promise((resolve, reject) => {
      props
        .getAllPlanningByTypeId(id)
        .then((res) => {
          setProjectList(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                    key: item.planningTypeId,
                    labelTemp: changeAlias(item.name),
                  };
                })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const onGetCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition(position.coords);
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };


  const onChangeProject = (data) => {
    data && history.push(`${UrlCollect.PlanningMap}/${data.value}`);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const loadOptions = async (search, prevOptions) => {
    await sleep(500);
    let arr = [];
    let filteredOptions;
    let filteredOptions1;
    if (!search) {
      filteredOptions = projectList;
    } else {
      const searchLower = changeAlias(search);

      filteredOptions = projectList.filter(({ labelTemp }) =>
        labelTemp.toLowerCase().includes(searchLower)
      );
      if (!(filteredOptions.length > 0)) {
        let searchArray = searchLower.split(" ");
        filteredOptions1 = projectList.filter(({ labelTemp }) => {
          arr = searchArray.filter((item) => labelTemp.includes(item));
          if (arr.length === searchArray.length) return true;
        });
        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions1.slice(
          prevOptions.length,
          prevOptions.length + 10
        );

        return {
          options: slicedOptions,
          hasMore,
        };
      }
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  const handleSelectAddress = (address) => {
    setAddress(address);
    geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setSelectLatLng(latLng);
        }).then(() => {
          if(hasPolygon == 0){
            props.GetAllPlanningBoundariesGeomText(45);
          }
        }).catch();
  };

  const handleClickSearchByLatLong = () => {
    if (props.listPlaningPolygon && Array.isArray(props.listPlaningPolygon) && props.listPlaningPolygon.length >0
        && selectLatLng.lat && selectLatLng.lng
    ) {
      setHasPolygon(1);
      let isInside = false;
      let planningId = 0;
      props.listPlaningPolygon.map((planning) => {
          let itemPolygon = planning.cordinatePolygon;
          if (
              mapHelper.isPointInPolygon(
                  selectLatLng.lat,
                  selectLatLng.lng,
                  itemPolygon
              )
          ) {
            isInside = true;
            planningId = planning.id;
            return;
          }
        });
        if (isInside && planningId >0) {
          history.push(
              `/xem-dia-diem/${planningId}/${selectLatLng.lat}/${selectLatLng.lng}`
          );
        } else {
          ShowNotification(
              viVN.Errors.CoordinateOutSitePolygon,
              NotificationMessageType.Warning,
              NotificationPosition.Center
          );
        }
    }else {
      ShowNotification(
          viVN.Errors.CoordinateOutSitePolygon,
          NotificationMessageType.Warning,
          NotificationPosition.Center
      );
    }
  };

  const handlePlacesError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
      <div className="home-search-box">
      <div className="home-search">
        <div className="vplan-logo">
          <img src={require("../../../assets/image/v-plan-logo.png")} />
        </div>
        <div className="home-title">
          CỔNG THÔNG TIN VÀ CƠ SỞ DỮ LIỆU TÍCH HỢP
        </div>
        <div className="home-title2">
          QUỐC GIA VỀ QUY HOẠCH
        </div>
        <div className="cb"></div>
        <div className="home-search-border">
          <div className="home-search-container">
            <div className="search-option-box">
              <input type="radio" id="search-planning" onChange={() => onSetSearchBox(true)}  name="search-option" defaultChecked="true" />
              <label htmlFor="search-planning">Quy hoạch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <input type="radio" id="search-place"  onChange={() => onSetSearchBox(false)}  name="search-option" />
              <label htmlFor="search-place">Địa điểm</label>
            </div>
            {searchPlanning && searchPlanning == true ? (
                <div className="search-box-planning">
                  <div className="search-planning-input">
                    <AsyncPaginate
                        className="react-select-container mr-4"
                        classNamePrefix="react-select"
                        cacheOptions
                        defaultMenuIsOpen={true}
                        defaultOptions
                        loadOptions={loadOptions}
                        onChange={onChangeProject}
                        placeholder="Nhập tên Quy hoạch - Dự án"
                        noOptionsMessage={() => "Không có dữ liệu"}
                    />
                  </div>
                  <div className="search-planning-button">
                    Tìm kiếm
                  </div>
                </div>
            ):(
                <div className="search-box-place">
                  <div className="search-place-input">
                    <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelectAddress}
                        onError={handlePlacesError}
                        clearItemsOnError={true}
                    >
                      {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="map__search-bar-container">
                            <div className="map__search-input-container">
                              <input
                                  {...getInputProps({
                                    placeholder: "Nhập địa điểm tra cứu",
                                    className: "input-search",
                                  })}
                              />
                            </div>
                            {suggestions.length > 0 && (
                                <div className="map__autocomplete-container">
                                  {suggestions.map((suggestion, index) => {
                                    const className = classnames(
                                        "map__suggestion-item",
                                        {
                                          "map__suggestion-item--active":
                                          suggestion.active,
                                        }
                                    );
                                    return (
                                        <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                            })}
                                        >
                                          <img
                                              src={require("../../../assets/icon/location-pointer.svg")}
                                              alt="location-pointer"
                                              className="mr-2 map__suggestion-item--location-pointer"
                                          />
                                          <span>{suggestion.description}</span>
                                        </div>
                                    );
                                  })}
                                </div>
                            )}
                          </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="search-place-button" onClick={handleClickSearchByLatLong} >
                    Tìm kiếm
                  </div>
                  <div className="cb"></div>
                </div>
            )}
          </div>
          <div className="cb"></div>
        </div>
        </div>
      <div className="cb"></div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon,
  listPlaningPolygon: state.mapData.listPlaningPolygon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getAllPlanningByTypeId: homePageStore.GetAllPlanningByTypeId,
      GetAllPlanningBoundariesGeomText: mapDataStore.GetAllPlanningBoundariesGeomText,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(HomeSearchView));
