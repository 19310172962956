/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap as baseMapIcon,
} from "@fortawesome/free-solid-svg-icons";

import {
  SetDisplayMaptoolPanel,
} from "../../../redux/store/open-layer/map-tools-panels.store";
import {
  BaseMapPropsModel,
} from "../../../models/planning-map-view/top-right-panel-models";
import ViLanguages from "../../../languages/vi";
import { Tooltip } from '@material-ui/core';
import "./basemap.scss";
const LanguageCollects = ViLanguages;

function Basemap(props: BaseMapPropsModel) {
  //--- Change base map
  const handleOnClickChangeBaseMap = (index: number) => {
    setOpenBaseMap(false);
    props.openLayerController.changeBaseMap(props.baseMapList[index].url);
  };

  //--- Open base map
  const [openBaseMap, setOpenBaseMap] = useState(false);
  const handleClickBaseMap = () => {
    setOpenBaseMap(!openBaseMap);
    props.SetDisplayMaptoolPanel(false);
  };

  return (
    <div id="entry-panel-right" className="position-absolute tools-box-right-container dropdown dropleft pt-1 pb-1">
      <div className="position-relative">
        <Tooltip title={LanguageCollects.planningMapView.baseMap}>
          <button className="btn" type="button" onClick={handleClickBaseMap}>
            <FontAwesomeIcon
                icon={baseMapIcon}
                color="#1c40a0"
            />
          </button>
        </Tooltip>

        {openBaseMap && props.baseMapList.length > 0 && (
            <div className="dropdown-base-map mt-1 border-0 pt-1 pb-1 bg-white rounded position-absolute shadow-sm">
              {props.baseMapList.map((basemap, index) => (
                  <div
                      key={index}
                      onClick={() => handleOnClickChangeBaseMap(index)}
                      className="dropdown-item"
                  >
                    {basemap.name}
                  </div>
              ))}
            </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Basemap));
