import MapDataModels from "../models/map-data-model-b/map-data-model";
import LayerSettingsModels from "../models/map-data-model-b/layer-setting-models";
import LayerCategoryModels from "../models/map-data-model-b/layer-category-models";
import BaseMapModels from "../models/map-data-model-b/base-map-models";

export const ExtracAllLayersArrays = (
  mapData: MapDataModels.MapDataModels
): LayerSettingsModels.LayerSettingsModel[] => {
  let result: LayerSettingsModels.LayerSettingsModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map != "MAP") {
      result = getListAllLayer(result,mapSetting.layer_categories);
      return;
    }
  });
  return result;
};

const getListAllLayer = (result:any,categoryList:any) => {
  categoryList.map((layerCategories:any) => {
    result = [...result, ...layerCategories.layer_settings];
    if(layerCategories.children && Array.isArray(layerCategories.children) && layerCategories.children.length >0){
      result = getListAllLayer(result,layerCategories.children);
    }
  });
  return result;
}

export const ExtracLayerListGroup = (
  mapData: MapDataModels.MapDataModels
): LayerCategoryModels.LayerCategoryModel[] => {
  let result: LayerCategoryModels.LayerCategoryModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      result = mapSetting.layer_categories;
      return;
    }
  });
  return result;
};

export const ExtractListLayerArray = (
  mapData: MapDataModels.MapDataModels
): LayerSettingsModels.LayerSettingsModel[] => {
  let ListLayer: LayerSettingsModels.LayerSettingsModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      ListLayer = getListLayer(ListLayer,mapSetting.layer_categories)
    }
  });
  return ListLayer;
};

const getListLayer = (ListLayer:any, CategoryList:any) => {
  CategoryList.map((layerCategory:any) => {
    layerCategory.layer_settings.map((layerSetting:any) =>
        ListLayer.push(layerSetting)
    );
    if(Array.isArray(layerCategory.children) && layerCategory.children.length >0){
      ListLayer = getListLayer(ListLayer,layerCategory.children)
    }
  });
  return ListLayer;
}

export const ExtractExtentArray = (
  mapData: MapDataModels.MapDataModels
): [number, number, number, number] => {
  const extent: [number, number, number, number] = [0, 0, 0, 0];
  mapData.extent
    .split(",")
    .map((numberValue, index) => (extent[index] = Number(numberValue)));
  return extent;
};
export const ExtractCenterArray = (
  mapData: MapDataModels.MapDataModels
): number[] => {
  const center: number[] = [];
  mapData.center
    .split(",")
    .map((numberValue) => center.push(Number(numberValue)));
  return center;
};

export const ExtractBasemapList = (
  mapData: MapDataModels.MapDataModels
): BaseMapModels.BaseMapModel[] => {
  let result: BaseMapModels.BaseMapModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      result = mapSetting.base_maps;
      return;
    }
  });
  return result;
};

export const ExtractSelectedBasemap = (
  mapData: MapDataModels.MapDataModels
): string => {
  let baseMapUrl = "";
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      mapSetting.base_maps.map((basemap) => {
        if (basemap.view_default) baseMapUrl = basemap.url;
        return;
      });
      return;
    }
  });
  return baseMapUrl;
};
