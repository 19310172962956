const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
      api: "https://quyhoachquocgia-api.cgis.asia/",
      DomainUserSite:"http://localhost:3000",
      DomainAdminSite:"http://localhost:3000",
      DomainName:""
  },
    /*production: {
              api: "https://quyhoachquocgia-api.cgis.asia/",
              DomainUserSite:"https://vplan.mpi.gov.vn",
              DomainAdminSite:"https://adminvplan.mpi.gov.vn",
              DomainName:"vplan.mpi.gov.vn"
      },
      */
     production: {
          api: "https://quyhoachquocgia-api.cgis.asia/",
          DomainUserSite:"https://quyhoachquocgia.cgis.asia",
          DomainAdminSite:"https://admin.quyhoachquocgia.cgis.asia",
          DomainName:"quyhoachquocgia.cgis.asia"
    },

};

module.exports = apiEnvironment[env];