/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./entry.scss";
import {Link} from "react-router-dom";

class Entry extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="entry-box">
                <div className="entry-container">
                    <div className="entry-item">
                        <div className="entry-item-border">
                            <Link to="/ban-do-quy-hoach/1">
                                <img
                                    src={require("../../../assets/icon/qh-quoc-gia.png")}
                                    alt="Quy Hoạch Cấp Quốc Gia"
                                />
                            </Link>
                            <div className="entry-text">
                                <a href="/ban-do-quy-hoach/1">Quy Hoạch<br/>Cấp Quốc Gia</a>
                            </div>
                        </div>
                    </div>
                    <div className="entry-item">
                        <div className="entry-item-border">
                            <a href="/quy-hoach-vung/5">
                                <img
                                    src={require("../../../assets/icon/qh-vung.png")}
                                    alt="Quy Hoạch Vùng"
                                />
                            </a>
                            <div className="entry-text">
                                <a href="/quy-hoach-vung/5">Quy Hoạch<br/>Vùng</a>
                            </div>
                        </div>
                    </div>
                    <div className="entry-item">
                        <div className="entry-item-border">
                            <a href="/quy-hoach-tinh/42">
                                <img
                                    src={require("../../../assets/icon/qh-tinh.png")}
                                    alt="Quy Hoạch Tỉnh"
                                />
                            </a>
                            <div className="entry-text">
                                <a href="/quy-hoach-tinh/42">Quy Hoạch<br/>Tỉnh</a>
                            </div>
                        </div>
                    </div>
                    <div className="entry-item" >
                        <div className="entry-item-border">
                            <a href="/quy-hoach/44">
                                <img
                                    src={require("../../../assets/icon/qh-do-thi-nong-thon.png")}
                                    alt="Quy Hoạch Đô Thị - Nông Thôn"
                                />
                            </a>
                            <div className="entry-text">
                                <a id="entry_do_thi_pc" href="/quy-hoach/44">Quy Hoạch<br/>Đô Thị - Nông Thôn</a>
                                <a id="entry_do_thi_mobile" href="/quy-hoach/44">Quy Hoạch<br/>Đ.Thị - N.Thôn</a>
                            </div>
                        </div>
                    </div>
                    <div className="entry-item" >
                        <div className="entry-item-border">
                            <Link to="/quy-hoach/45">
                                <img
                                    src={require("../../../assets/icon/qh-nganh.png")}
                                    alt="QH Có Tính Chất KT, Chuyên Ngành"
                                />
                            </Link>
                            <div className="entry-text">
                                <a href="/quy-hoach/45">QH Có Tính Chất<br/>KT, Chuyên Ngành</a>
                            </div>
                        </div>
                    </div>
                    <div className="cb"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
