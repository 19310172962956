/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomeSliderView from "./slider/slider.view";
import * as appActions from "../../core/app.store";
import HomeEntry from './entry/entry';

import {
  getUserInfo,
} from "../../utils/configuration";
import "./home.scss";
import HomeSearchView from "./search/search.view"

function HomeDesktop(props) {
  const { showLoading } = props;

  const isLogin = getUserInfo() ? true : false;
  return (
    <div className="home-page">
      <HomeSearchView />
      <div className="cb"></div>
      <HomeSliderView />
      <HomeEntry isLogin = {isLogin} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
